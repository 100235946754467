<template>
  <div class="all">
    <!--    顶部Banner-->
    <div class="topTitle">
      <div class="title">
        <!--        顶部logo-->
        <div class="imgLogo" @click="home"><img src="../../assets/img/logo2.png" class="home"></div>
      </div>
      <div class="cont">
        <div class="login">
          <span>新密码</span>
          <div class="input_two">
            <div class="input_phone"><img src="../../assets/img/password.png">
              <el-input v-model="newPwd" class="input_i" placeholder="请输入您的新密码" show-password></el-input>
            </div>
            <div class="input_phone"><img src="../../assets/img/password.png">
              <el-input v-model="pwd" class="input_p" placeholder="请再次输入您的新密码" show-password></el-input>
            </div>
            <!--            <router-link :to="{name:'update_pwd'}" class="update_pwd"> 忘记密码</router-link>-->
          </div>
          <div class="login_d" @click="determine">确认</div>
          <div class="tab_but">
            <router-link :to="{name:'login'}" class="text_login" style="padding: 0px 20px"> 账号登录</router-link>
            <router-link :to="{name:'quick_login'}" class="text_login"><p>手机快捷登录</p></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { enterprise } from '@/api/api'

export default {
  name: 'new_pwd',
  data () {
    return {
      newPwd: '',
      pwd: ''
    }
  },
  created () {
  },
  methods: {
    determine () {
      if (this.pwd === '' || this.pwd === null) {
        this.$message.error('请输入您的账户密码！')
      } else if (this.newPwd === '' || this.newPwd === null) {
        this.$message.error('请输入您的确认登录密码！')
      } else if (this.newPwd !== this.pwd) {
        this.$message.error('登录密码和确认密码不同！')
      } else {
        axios.defaults.headers.token = localStorage.getItem('token')
        enterprise({
          method: 'updatePwd',
          formCode: 'member',
          data: [
            {
              phone: this.$route.params.phone,
              password: this.pwd
            }
          ]
        }, res => {
          this.$message.success('修改密码成功！')
          this.$router.replace('/login/login')
        })
      }
    },
    home () {
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.all {
  width: 100%;
  height: 1080px;
  margin: 0 auto;
}
.home{
  cursor: pointer;
}
/*顶部title*/
.topTitle {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: url("../../assets/img/login_bk.png");
  background-size: 100% 100%;
}

.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}

.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 990px;
  /*background-color: blueviolet;*/
  /*text-align: center;*/
}

/*登录框*/
.login {
  margin: 0px auto;
  width: 400px;
  height: 390px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
}

.login > span {
  font-size: 18px;
  color: #323333;
  line-height: 88px;
  letter-spacing: 1px;
}

/*input框*/
.input_phone > img {
  position: absolute;
  margin-left: -20px;
  margin-top: 30px;
  width: 20px;
  height: 20px;
}

.input_i {
  width: 300px;
  margin-left: 10px;
  margin-top: 20px;
}

.input_p {
  width: 300px;
  margin-left: 14px;
  margin-top: 20px;
}

/*忘记密码*/
.update_pwd {
  font-size: 14px;
  color: #EA5D5C;
  line-height: 40px;
  margin-left: 270px;
  cursor: pointer;
}

/*登录按钮*/
.login_d {
  width: 320px;
  height: 50px;
  background-color: #10539B;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  color: white;
  margin-left: 40px;
  border-radius: 4px;
  margin-top: 19px;
  letter-spacing: 1px;
  cursor: pointer;
}

/*跳转*/
.tab_but {
  display: flex;
  justify-content: space-between;
  padding: 19px 30px;
}

.text_login {
  color: #666666;
  font-size: 14px;
  cursor: pointer;
}
</style>
